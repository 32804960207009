<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.bundle_id"
            dense
            label="System Code"
            item-text="systemCode"
            item-value="skuId"
            clearable
            outlined
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            :error-messages="bundleIdErrors"
            @input="$v.formData.bundle_id.$touch()"
            @blur="$v.formData.bundle_id.$touch()"
          ></v-autocomplete>
        </div>

        <div class="mt-4 mb-6 pa-0 w-100">
          <SkuDatatable :setSku="setSkus" :skus="formData.skus"></SkuDatatable>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import SkuDatatable from "@/own/components/fulfillment/bundles/skuEditContainer/SkuDatatable";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { SkuDatatable },
  validations: {
    formData: {
      bundle_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,
    serverData: null,
    formData: {
      bundle_id: null,
      skus: [],
    },
  }),
  methods: {
    loadDataFromServer() {
      this.formData.bundle_id = this.$store.getters.getItemForAction.id;
      // this.entries.push({
      //   skuId: this.$store.getters.getItemForAction.id,
      //   systemCode: this.$store.getters.getItemForAction.systemCode,
      // });
      this.getData(this.$store.getters.getItemForAction.systemCode);
      let sendData = { id: this.$store.getters.getItemForAction.id };
      this.pageLoader(true);
      ApiService.post("/fulfillment/bundles/edit", sendData)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    submitEditForm() {
      let sendData = {
        old_bundle_id:
          this.formData.bundle_id === this.$store.getters.getItemForAction.id
            ? null
            : this.$store.getters.getItemForAction.id,
        bundle_id: this.formData.bundle_id,
        skus: this.formData.skus.map((item) => {
          return {
            sku_id: item.sku_id,
            quantity: item.quantity,
          };
        }),
      };
      this.pageLoader(true);
      ApiService.post("/fulfillment/bundles/update", sendData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Bundle data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = !this.dialog;
          // this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      if (this.serverData) {
        let copy = { ...this.serverData.bundle };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.bundle).forEach((serverD) => {
            if (localD[0] === serverD[0]) {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
      } else {
        this.formData = {
          bundle_id: null,
          skus: [],
        };
      }
    },
    async getData(value) {
      await fetch(
        `${process.env.VUE_APP_BASE_URL}/skus/search?sku_type=bundle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ q: value }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    bundleIdErrors: function () {
      return this.handleFormValidation("bundle_id", this);
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      this.getData(val);
    },
  },
};
</script>
